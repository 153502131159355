import Head from 'next/head';
import { useRouter } from 'next/router';
import { baseUrl } from 'utils/constant';

interface Props {
  pageTitle?: string;
  pageDescription?: string;
  pagePath?: string;
  pageImg?: string;
  pageImgWidth?: number;
  pageImgHeight?: number;
}

const Seo = ({
  pageTitle,
  pageDescription,
  pagePath,
  pageImg,
  pageImgWidth,
  pageImgHeight
}: Props) => {
  const router = useRouter();

  const defaultTitle = '채용을 혁신하다. 세컨팀';
  const defaultDescription = '스타트업과 IT회사 경험이 있는 검증된 인력들로 구성된 리모트 팀과 프로젝트를 진행해보세요.';
  const defaultPath = router?.asPath || '/';

  const title = pageTitle || defaultTitle;
  const description = pageDescription || defaultDescription;
  const url = `${baseUrl}${pagePath || defaultPath}`;
  const imgUrl = pageImg || '/images/og-img.png';
  const imgWidth = pageImgWidth || 1280;
  const imgHeight = pageImgHeight || 640;

  return (
    <Head>
      <title>{defaultTitle}</title>
      <meta name="viewport" content="initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width, target-densitydpi=medium-dpi" />
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:width" content={String(imgWidth)} />
      <meta property="og:image:height" content={String(imgHeight)} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      <link rel="canonical" href={url} />
    </Head>
  );
};

export default Seo;
