import axios from "axios";
import applyCaseMiddleware from 'axios-case-converter';
import { apiUrl, legacyUrl } from 'utils/constant';
import AuthHelper from "./auth-helper";
import GlobalStore, { StoreKey } from "./global-store";
import * as Sentry from "@sentry/nextjs";

const tokenName = StoreKey.TOKEN;

const instance = axios.create({
  baseURL: apiUrl,
});

const api = applyCaseMiddleware(instance);

api.interceptors.request.use(async (config) => {
  if (typeof window !== 'undefined') {
    const token = GlobalStore.get(tokenName);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  Sentry.addBreadcrumb({
    category: 'api',
    level: 'info',
    message: JSON.stringify({
      baseURL: config.baseURL,
      accessToken: (config.headers.Authorization as string || '').replace('Bearer ', ''),
      url: config.url,
      method: config.method,
      params: config.params,
      data: config.data,
    })
  })

  return config;
});

api.interceptors.response.use(
  res => {

    return res;
  },
  err => {
    // global 401 handler
    const token = GlobalStore.get(tokenName);

    try {
      if (err.response.status === 401 && token) {
        AuthHelper.expireLogin();
        return;
      }
  
      // CSR 호출에서 500 오류가 떴을 때
      if (err.response.status === 500 && typeof window !== 'undefined') {
        Sentry.captureException(err);
      }
    } catch (err) {

    }

    throw err;
  },
);

export default api;
