import Member from "networks/models/member";
import MemberAccount from "networks/models/member-account";
import Partner from "networks/models/partner";
import PartnerAccount from "networks/models/partner-account";
import RegularSurvey from "networks/models/regular-survey";
import AuthHelper from "utils/auth-helper";
import { isMemberMode } from "utils/constant";
import Service from "./service";
import Episode from "networks/models/episode";

export type MemberOrPartner = Member & Partner;
export type MemberOrPartnerAccount = MemberAccount & PartnerAccount;

class _UserService extends Service {
  async getProfile(): Promise<MemberOrPartner> {
    const uuid = AuthHelper.getUUID(this.ctx);
    if (isMemberMode) {
      const { data } = await this.api.get(`/members/${uuid}/profile`);
      return data;
    }

    const { data } = await this.api.get(`/partner/${uuid}/profile`);
    return data;
  }

  // 회사전용
  async getMemberProfile(uuid: string): Promise<Member> {
    const { data } = await this.api.get(`/members/${uuid}/profile`);
    return data;
  }

  async getMemberProjectList(uuid: string): Promise<Episode[]> {
    const { data } = await this.api.get(`/members/${uuid}/episodes`);
    return data.results;
  }

  async getAccount(): Promise<MemberOrPartnerAccount> {
    const uuid = AuthHelper.getUUID(this.ctx);
    if (isMemberMode) {
      const { data } = await this.api.get(`/members/${uuid}`);
      return data;
    }

    const { data } = await this.api.get(`/partner/${uuid}`);
    return data;
  }

  async getRegularSurveyList(): Promise<RegularSurvey[]> {
    const { data } = await this.api.get('/regular-surveys');
    return data;
  }

  async putAccount(form: any): Promise<void> {
    const uuid = AuthHelper.getUUID(this.ctx);

    if (isMemberMode) {
      await this.api.put(`/members/${uuid}`, form);
      return;
    }

    await this.api.put(`/partner/${uuid}`, form);
  }

  async putProfile(form: any): Promise<void> {
    const uuid = AuthHelper.getUUID(this.ctx);
    await this.api.put(`/members/${uuid}/profile`, form);
  }

  async putAvatar(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('avatar', file);

    const uuid = AuthHelper.getUUID(this.ctx);
    await this.api.put(`/members/${uuid}/profile`, formData);
  }

  async postRegularSurveySubscription(id: number) {
    await this.api.post(`/regular-surveys/${id}/subscription`);
  }

  async deleteRegularSurveySubscription(id: number) {
    await this.api.delete(`/regular-surveys/${id}/subscription`);
  }
}

// todo Member 서비스 / Partner 서비스 분기하기
const UserService = new _UserService();
export default UserService;
