import Header from 'components/layouts/Header';
import Footer from 'components/layouts/Footer';
import Head from 'next/head';
import { ReactNode } from 'react';
import NotiBar from 'components/atomics/NotiBar';
import styled from 'styled-components';
import SideBar from './SideBar';
import Modal from '../layouts/Modal';
import { useRouter } from 'next/router';

type Props = {
  children?: ReactNode
  // title?: string
}

const Root = styled.div`
  background: ${process.env.isLegacy ? 'white' : '#F3F4F6'};
`

const Main = styled.main`
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;
`;

const LegacyMain = styled.div`
  max-width: 1073px;
  margin: 0 auto;
  padding: 35px 30px;
`;

const Layout = ({ children }: Props) => {
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <Root id="root">
        {/* <NotiBar text="세컨팀 N잡 멤버들로 필요한 전문인력을 찾아보세요." /> */}

        {
          process.env.isLegacy ?
          <LegacyMain>
            {children}
          </LegacyMain>
          :
          <SideBar>
            {/* <Header /> */}

            <Main>
              {children}
            </Main>

            <Footer />
          </SideBar>
        }

      </Root>

      <Modal />
    </div>
  );
};

export default Layout;
