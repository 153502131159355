import Image from "next/image";

interface Props {
  src?: string;
  size: number;
}

const Avatar = ({ src, size }: Props) => {
  return (
    <div
      className={`inline-block h-${size} w-${size} rounded-full relative`}
    >
      <Image
        src={src || '/images/@photo.png'}
        className="rounded-full"
        alt="프로필 이미지"
        width={size * 4}
        height={size * 4}
        style={{ 
          position: 'relative',
          objectFit: 'cover',
          width: size * 4,
          height: size * 4,
        }}
      />
    </div>
  );
};

export default Avatar;
