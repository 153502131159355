import { destroyCookie, parseCookies, setCookie } from 'nookies'

export enum StoreKey {
  TOKEN = 'token',
}

class _GlobalStore {
  save(key: StoreKey, value: any, ctx: any = null) {
    setCookie(ctx, key, value, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
      sameSite: 'strict',
      secure: true,
      // domain: '', // todo 도메인 제한 걸기
    });
  }

  get(key: StoreKey, ctx: any = null) {
    const cookies = parseCookies(ctx);
    return cookies[key];
  }

  clear(ctx: any = null) {
    destroyCookie(ctx, StoreKey.TOKEN);
    // todo 다른 키들도 클리어하기
  }
}

const GlobalStore = new _GlobalStore();
export default GlobalStore;
