import Image from "next/image";
import Link from 'next/link';
import styled from 'styled-components';

const LogoImage = styled(Image)`
  cursor: pointer;
`;

interface Props {
  width?: number;
  height?: number;
}

const Logo = ({
  width = 169,
  height = 50,
}: Props) => (
  <Link href="/me">
    <LogoImage
      src="/images/logo.svg"
      alt="second-team logo"
      width={width}
      height={height}
    />
  </Link>
);

export default Logo;
