import { ModalKey } from 'components/layouts/Modal';
import create from 'zustand';

interface ModalStore {
  currentKey?: ModalKey;
  data?: any; // generic 활용
  show: (key: ModalKey, data?: any) => void;
  hide: () => void;
}

const useModalStore = create<ModalStore>(set => (
  {
    currentKey: null,
    data: {},
    show: (key, data) => set((state) => {
      return {
        ...state,
        currentKey: key,
        data,
      };
    }),
    hide: () => set((state) => {
      return {
        ...state,
        currentKey: null
      };
    }),
  }
));

export default useModalStore;
