import { legacyUrl } from "./constant";
import GlobalStore, { StoreKey } from "./global-store";
import jwt_decode from "jwt-decode";
import Token from "interfaces/Token";
import { NextPageContext } from "next";

class _AuthHelper {
  shouldLogin() {
    if (!this.checkLogin()) {
      this.alertLogin();
      this.redirectToLogin();

      return true;
    }

    return false;
  }

  expireLogin() {
    GlobalStore.clear();
    this.alertLogin();
    this.redirectToLogin();
  }

  getUUID(ctx: NextPageContext = null): string {
    try {
      const token = GlobalStore.get(StoreKey.TOKEN, ctx);
      var decoded: Token = jwt_decode(token);
      return decoded.uuid;
    } catch (err) {
      this.alertLogin();
      this.redirectToLogin();
    }
  }

  checkLogin() {
    const token = GlobalStore.get(StoreKey.TOKEN);
    return token && token.length > 0;
  }

  alertLogin() {
    try {
      alert('로그인이 필요한 서비스입니다');
    } catch (err) {
      
    }
  }

  redirectToLogin() {
    try {
      window.location.href = `${legacyUrl}/login`;
    } catch (err) {
      // 서버사이드에서 호출
    }
  }
}

const AuthHelper = new _AuthHelper();
export default AuthHelper;
