import Layout from 'components/layouts/Layout';
import GoogleAnalytics from 'components/utils/GoogleAnalytics';
import Seo from 'components/utils/Seo';
import 'styles/globals.css';

import { Analytics } from '@vercel/analytics/react';
import Loader from 'components/atomics/loader';
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';
import UserService from 'networks/services/user-service';
import { Router } from 'next/router';
import { useEffect, useState } from 'react';
import useUserStore from 'stores/user-store';
import GlobalStore, { StoreKey } from 'utils/global-store';
import * as Sentry from "@sentry/nextjs";

dayjs.extend(relativeTime);
dayjs.locale('ko')

const App = ({ Component, pageProps }) => {
  const { me, setMe, setAccount } = useUserStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();

    const start = () => {
      setLoading(true);
    };

    const end = () => {
      setLoading(false);
    };

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);

    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  const load = async () => {
    // 이미 로딩한경우 중복 로딩하지 않기
    if (Object.keys(me).length > 0) {
      return;
    }

    const profile = await UserService.getProfile();
    setMe(profile);

    const account = await UserService.getAccount();
    setAccount(account);

    Sentry.setUser({
      id: account.uuid,
      email: account.email,
      username: account.name,
    });
  }

  if (Object.keys(me).length < 1) {
    return <></>;
  }

  return (
    <>
      <GoogleAnalytics />
      <Analytics />
      <Seo />

      <Layout>
        <Loader
          loading={loading}
        />

        <Component {...pageProps} />
      </Layout>
    </>
  );
};

App.getInitialProps = async ({ ctx }) => {
  const { token } = ctx.query;

  if (token) {
    GlobalStore.save(StoreKey.TOKEN, token, ctx);
  }

  return {

  };
};

export default App;
