import { AxiosInstance } from "axios";
import { NextPageContext } from "next";
import api from "utils/api";
import GlobalStore, { StoreKey } from "utils/global-store";

class Service {
  ctx: NextPageContext = null;
  api: AxiosInstance = api;
  
  withContext(ctx: any) {
    // NOTE 서버 단의 axios에서 공통된 api 모듈에 default로 토큰을 지정하는 경우
    // 보안 취약점이 발생할 수 있으니 요청별로 인스턴스 나눠쓰기

    const token = GlobalStore.get(StoreKey.TOKEN, ctx);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    this.ctx = ctx;

    return this;
  }

  // iframe 교차 도메인 쿠키 획득 해결을 위한 임시 함수
  // legacy에서만 사용
  withToken(token: string) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return this;
  }
}

export default Service;
