import Member from 'networks/models/member';
import MemberAccount from 'networks/models/member-account';
import RegularSurvey from 'networks/models/regular-survey';
import { MemberOrPartner, MemberOrPartnerAccount } from 'networks/services/user-service';
import create from 'zustand';

interface UserStore {
  me: MemberOrPartner;
  account: MemberOrPartnerAccount;
  regularSurveyList: RegularSurvey[];
  setMe: (data: MemberOrPartner) => void;
  setAccount: (data: MemberOrPartnerAccount) => void;
  setRegularSurveyList: (data: RegularSurvey[]) => void;
}

// set method로 상태 변경 가능
const useUserStore = create<UserStore>(set => (
  {
    me: {} as MemberOrPartner,
    account: {} as MemberOrPartnerAccount,
    regularSurveyList: [] as RegularSurvey[],
    setMe: (data) => set((state) => {
      return {
        ...state,
        me: data,
      };
    }),
    setAccount: (data) => set((state) => {
      return {
        ...state,
        account: data,
      };
    }),
    setRegularSurveyList: (data) => set((state) => {
      return {
        ...state,
        regularSurveyList: data,
      }
    })
  }
));

export default useUserStore;
